import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useNavigate
} from 'react-router-dom';

import AppStore from '../../config/AppStore';

import { createAdvertiserOfferBtnClick } from "../../view_logics/AdvertiserOffers/CreateAdvertiserOffer"

import CreateAdvertiserOffer from "./CreateAdvertiserOffer";
import AdvertiserOffersList from "./AdvertiserOffersList";

function AdvertiserOffers() {
  const [showCreateAdvertiserContent, setShowCreateAdvertiserContent] = useState(false);
  const handleCreateAdvertiserClick = (menuItem) => {
    setShowCreateAdvertiserContent(menuItem);
  };


  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: 'create',
    history: navigate
  };

  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Advertiser Offers</h1>
          {!showCreateAdvertiserContent ?
            <div className="col-md-3">
              <a
                // onClick={() => handleCreateAdvertiserClick('createadvertiser')}
                onClick={() => createAdvertiserOfferBtnClick(inputObj)}
                className="btn btn-primary float-right mb-3"
              >
                Create New Offer
              </a>
            </div>
            :
            <div className="col-md-3">
              <a
                onClick={() => handleCreateAdvertiserClick(false)}
                className="btn btn-primary float-right mb-3"
              >
                Back
              </a>
            </div>
          }
          <hr />
          {showCreateAdvertiserContent ? <CreateAdvertiserOffer /> : <AdvertiserOffersList />}
        </div>
      </main>
    </div>
  );
};

export default AdvertiserOffers;
