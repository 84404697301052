import Constants from "../../config/Constants";
import { sendPOSTRequest } from "../../utils/common_functions";

export const sendRequestGetOfferStatsReports = async (dataToPost) => {
  console.log("sendRequestGetPublishers");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_REPORTING_OFFER_STATS_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost.data,
  };
  delete data["accessToken"];
  
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendRequestGetPublishersReportsSuccessHandler,
    sendRequestGetPublishersReportsErrorHandler
  );

  function sendRequestGetPublishersReportsSuccessHandler(response, request) {
    console.log(
      "🚀 ~ file: CreatePublisher.js:85 ~ sendCreatePublisherFormSubmitResponseSuccessHandler ~ response:",
      response,
      request
    );
    if(Array.isArray(response)){
      dataToPost?.loadingHandler && dataToPost?.loadingHandler(false)
        dataToPost?.dataHander && dataToPost?.dataHander(response)
    }
  }
};



function sendRequestGetPublishersReportsErrorHandler(error, request) {
  console.log(
    "🚀 ~ file: Reports.js:33 ~ sendRequestGetPublishersReportsErrorHandler ~ error:",
    error,
    request
  );
  request?.loadingHandler && request?.loadingHandler(false)
  //   request.history("", { state: { from: "Publisher", type: 'error', message: error.message } });
}


export const sendRequestGetPublisherOfferStatReports = async (dataToPost) => {
  console.log("sendRequestGetPublishers");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_PUBLISHER_OFFER_STATS_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost.data,
  };
  delete data["accessToken"];
  
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendRequestGetPublishersReportsSuccessHandler,
    sendRequestGetPublishersReportsErrorHandler
  );

  function sendRequestGetPublishersReportsSuccessHandler(response, request) {
    console.log(
      "🚀 ~ file: CreatePublisher.js:85 ~ sendCreatePublisherFormSubmitResponseSuccessHandler ~ response:",
      response,
      request
    );
    if(Array.isArray(response)){
      dataToPost?.loadingHandler && dataToPost?.loadingHandler(false)
        dataToPost?.dataHander && dataToPost?.dataHander(response)
    }
  }
};


function sendRequestGetMonthlyRevenueReportsErrorHandler(error, request) {
  console.log(
    "🚀 ~ file: Reports.js:93 ~ sendRequestGetMonthlyRevenueReportsErrorHandler ~ error:",
    error,
    request
  );
  if(request.loadingHandler){
    request.loadingHandler(false)
  }
}


export const sendRequestGetMonthlyRevenueReports = async (dataToPost) => {
  console.log("sendRequestGetMonthlyRevenueReports");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_MONTHLY_REVENUE_REPORTS_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost.data,
  };
  delete data["accessToken"];
  
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendRequestGetMonthlyRevenueReportsSuccessHandler,
    sendRequestGetMonthlyRevenueReportsErrorHandler
  );

  function sendRequestGetMonthlyRevenueReportsSuccessHandler(response, request) {
    console.log(
      "🚀 ~ file: CreatePublisher.js:85 ~ sendCreatePublisherFormSubmitResponseSuccessHandler ~ response:",
      response,
      request
    );
    if(Array.isArray(response)){
        dataToPost?.dataHander && dataToPost?.dataHander(response)
    }
  }
};


export const sendRequestGetMonthlyRevenueReportsAdmin = async (dataToPost) => {
  console.log("sendRequestGetMonthlyRevenueReportsAdmin");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_MONTHLY_REVENUE_REPORTS_ADMIN_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost.data,
  };
  data.loadingHandler = dataToPost.loadingHandler
  delete data["accessToken"];
  
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendRequestGetMonthlyRevenueReportsAdminSuccessHandler,
    sendRequestGetMonthlyRevenueReportsErrorHandler
  );

  function sendRequestGetMonthlyRevenueReportsAdminSuccessHandler(response, request) {
    console.log(
      "🚀 ~ file: CreatePublisher.js:85 ~ sendCreatePublisherFormSubmitResponseSuccessHandler ~ response:",
      response,
      request
    );
    if(Array.isArray(response)){
        dataToPost?.dataHander && dataToPost?.dataHander(response)
    }
  }
};


export const sendRequestUpdateMonthlyRevenueReportsAdmin = async (dataToPost) => {
  console.log("sendRequestGetMonthlyRevenueReportsAdmin");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_UPDATE_MONTHLY_REVENUE_REPORTS_ADMIN_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost.data,
  };
  delete data["accessToken"];
  
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendRequestGetMonthlyRevenueReportsAdminSuccessHandler,
    sendRequestGetMonthlyRevenueReportsErrorHandler
  );

  function sendRequestGetMonthlyRevenueReportsAdminSuccessHandler(response, request) {
    console.log(
      "🚀 ~ file: CreatePublisher.js:85 ~ sendCreatePublisherFormSubmitResponseSuccessHandler ~ response:",
      response,
      request
    );
    if(Array.isArray(response)){
        dataToPost?.dataHander && dataToPost?.dataHander(response)
    }
  }
};

