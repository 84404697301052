import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { logoutAction } from "../../view_logics/LoginSlice";
import AppStore from "../../config/AppStore";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LogoutDropdown = ({
  open = false,
  setOpen = null,
  customClickAreaRef = null,
  setOpenApiModal = null
}) => {
  const store = AppStore.getAppStoreInstance();
  const {userId, username, userType } = useSelector((state) => state?.login);
  const dropdownRef = useRef(null);
  const navigate = useNavigate()


  const logoutHandler = () => {
    store.dispatch(logoutAction());
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      customClickAreaRef.current &&
      !customClickAreaRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener("click", handleClickOutside);
    // Detach the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Dropdown show={open} ref={dropdownRef}>
      {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
      Dropdown Button
    </Dropdown.Toggle> */}

      <Dropdown.Menu id="dropdown-basic">
        <Dropdown.Item onClick={()=> {navigate('/dashboard', { replace: true }); setOpen(false)}}>Dashboard</Dropdown.Item>
        {userType == "admin" ? "" : <Dropdown.Item onClick={()=> {navigate('/contact_info', { replace: true }); setOpen(false)}}>User Profile</Dropdown.Item>}
        {userType == "admin" ? "" : <Dropdown.Item onClick={()=> { if(setOpenApiModal) {setOpenApiModal(true);} setOpen(false)}}>API Integration</Dropdown.Item>}
        <Dropdown.Item onClick={() => logoutHandler()}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LogoutDropdown;
