import { React, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";

import BasicInfoTab from "./BasicInfoTab";
import LeadSettingsTab from "./LeadSettingsTab";
import TargetingTab from "./TargetingTab";
import ImagesInfoTab from './ImagesInfoTab';

import AppStore from '../../config/AppStore';

import { sendRequestGetAdvertisersList, sendRequestGetTagsList } from '../../view_logics/AdvertiserOffers/AdvertiserOffer';
import PublishersTab from './PublishersTab';

const CreateAdvertiserOffer = () => {


    const [advertisersList, setAdvertisersList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    console.log("🚀 ~ file: CreateAdvertiserOffer.js:18 ~ CreateAdvertiserOffer ~ tagsList:", tagsList)

    let store = AppStore.getAppStoreInstance();
    const navigate = useNavigate(); // we can pass navigation to clickhandler
    const inputObj = {
        accessToken: store.getState().login.accessToken,
        url: '',
        history: navigate,
        dataHandler: setAdvertisersList,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resultT = await sendRequestGetTagsList(inputObj);
                // console.log('advertisersList has been poulated');
                const tagsList1 = [];
                for (let key in resultT) {
                    tagsList1.push({ id: resultT[key].id, name: resultT[key].name });
                }
                setTagsList(tagsList1);
                const results = await sendRequestGetAdvertisersList(inputObj);
                // console.log('advertisersList has been poulated');
                const advertisersList1 = [];
                for (let key in results) {
                    advertisersList1.push({ id: results[key].id, name: results[key].name });
                }
                setAdvertisersList(advertisersList1);
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const formik = useFormik({
        initialValues: {
            // Initial values for form fields
            // ...
        },
        onSubmit: (values) => {
            // Handle form submission
            // ...
        },
    });

    const [activeTab, setActiveTab] = useState(1);
    console.log("🚀 ~ file: CreateAdvertiserOffer.js:65 ~ CreateAdvertiserOffer ~ activeTab:", activeTab)

    const tabComponents = {
        1: BasicInfoTab,
        2: ImagesInfoTab,
        3: LeadSettingsTab,
        4: TargetingTab,
        // 5: PublishersTab
    };

    const TabContentComponent = tabComponents[activeTab];

    return (
        <div className="">
      <main className="content">
        <div>
            <h1>Create New Offer</h1>
            <div className="col-12 col-lg-12">
                <div className="tab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li 
                            role="presentation"
                            className='nav-item'
                            
                            onClick={() => setActiveTab(1)}
                        >
                            <a className={activeTab === 1 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Basic Information</a>
                            
                        </li>
                        <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(2)}
                        >
                            
                            <a className={activeTab === 2 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Images Information</a>
                        </li>
                        <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(3)}
                        >
                            
                            <a className={activeTab === 3 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Lead Settings</a>
                        </li>
                        <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(4)}
                        >
                            <a className={activeTab === 4 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Targeting</a>
                        </li>
                        {/* <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(5)}
                        >
                            <a className={activeTab === 5 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Publishers</a>
                        </li> */}
                    </ul>

                    {/* Render the selected tab component */}
                    <div className="tab-content">
                        {/* <TabContentComponent advertiserOfferInfo={{}}/> */}
                        <TabContentComponent advertiserOfferInfo={{}} setActiveTab={setActiveTab} advertisersList={advertisersList} tagsList={tagsList}/>
                    </div>
                </div>
            </div>
        </div>
        </main>
        </div>

    );
};

export default CreateAdvertiserOffer;
