import React, { useEffect, useRef, useState } from "react";
import PaginationComponent from "../../components/common/PaginationComponent";
import AppStore from "../../config/AppStore";
import {
  sendRequestGetMonthlyRevenueReportsAdmin,
  sendRequestUpdateMonthlyRevenueReportsAdmin,
} from "../../view_logics/Reports/Reports";
import { format, getMonth, getYear } from "date-fns";
import { addLeadingZero } from "../../../utils/common_functions";
import { useSelector } from "react-redux";
import ReportStatusDropdown from "../../components/common/ReportStatusDropdown";
import DatePickerField from "../../components/common/DatePickerField";
import ModalComponent from "../../components/common/Modal";

const MonthlyRevenueReportsListAdmin = () => {
  const { publisherId } = useSelector((state) => state?.login);
  const customClickAreaRef = useRef(null);
  const [reportsFilterLoading, setReportsFilterLoading] = useState(false);
  console.log(
    "🚀 ~ MonthlyRevenueReportsListAdmin ~ reportsFilterLoading:",
    reportsFilterLoading
  );

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(new Date());

  const [selectedReportToUpdate, setSelectedReportToUpdate] = useState(null);
  console.log("🚀 ~ MonthlyRevenueReportsListAdmin ~ selectedReportToUpdate:", selectedReportToUpdate)

  const [openDropdown, setOpenDropDown] = useState(false);
  const statusDropDownHandler = () => {
    setOpenDropDown(!openDropdown);
  };
  const [monthDate, setMonthDate] = useState(
    `${addLeadingZero(getMonth(new Date()) + 1)}/${getYear(new Date())}`
  );

  let store = AppStore.getAppStoreInstance();
  const [apiData, setApiData] = useState([]);
  const [reportsList, setReportsList] = useState([]);

  const [displayItems, setDisplayItems] = useState([]);
  console.log(
    "🚀 ~ MonthlyRevenueReportsListAdmin ~ displayItems:",
    displayItems
  );

  const [displayIndex, setDisplayIndex] = useState(null);
  // const [selectedYear, setSelectedYear] = useState("2024");
  // const [selectedYear, setSelectedYear] = useState(
  //   new Date().getFullYear().toString()
  // );
  const lastTenYears = Array.from({ length: 10 }, (_, index) =>
    (new Date().getFullYear() - index).toString()
  );

  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "reports",
    data: { month_date: "" },
    loadingHandler: setReportsFilterLoading,
    dataHander: setApiData,
  };

  useEffect(() => {
    if (Array.isArray(apiData)) {
      // Group data by billing month
      const groupedData = Object.values(
        apiData.reduce((acc, item) => {
          const date = new Date(item.stats.date);
          const month = date.toLocaleString("en-US", { month: "long" });
          const year = date.getFullYear();
          const key = `${month} ${year}`;
          if (!acc[key]) {
            acc[key] = { month: month, year: year, items: [] };
          }
          acc[key].items.push(item);
          return acc;
        }, {})
      );

      // Render grouped data in a table
      console.log("groupedData", groupedData);
      setReportsList(groupedData);
      setReportsFilterLoading(false);
    }
  }, [apiData]);

  const fetchMonthlyReports = async (date) => {
    try {
      let response;
      // Fetch records for the selected year
      response = await sendRequestGetMonthlyRevenueReportsAdmin({
        ...inputObj,
        data: { month_date: date },
      });
      setReportsList(response);
    } catch (error) {
      console.log("Error fetching monthly reports:", error);
      setReportsFilterLoading(false);
    }
  };

  useEffect(() => {
    fetchMonthlyReports(monthDate);
  }, []);

  const nestedItemDisplay = (items) => {
    const groupedData = {};

    // Iterate through the array and group objects by date
    if (items.length > 0) {
      items.forEach((item) => {
        const { website_domain } = item;
        if (!groupedData[website_domain]) {
          groupedData[website_domain] = [item];
        } else {
          groupedData[website_domain].push(item);
        }
      });
    }
  };

  const sumOfFieldForRow = (row, fieldName) => {
    const sum = row.items.reduce((sum, item) => {
      return sum + item.stats[fieldName];
    }, 0);
    return sum.toFixed(2);
  };

  const uniqueNames = new Set();

  const applyFilterHandler = async () => {
    setReportsFilterLoading(true);
    let updatedMonth = getMonth(selectedMonth) + 1;
    let updatedYear = getYear(selectedYear);
    let formatedMonth;
    if (updatedMonth) {
      formatedMonth = addLeadingZero(updatedMonth);
    }

    if (formatedMonth && updatedYear) {
      let postedDate = `${formatedMonth}/${updatedYear}`;
      fetchMonthlyReports(postedDate);
    }

    // setDisplayStats([]);
    // setDisplayIndex(null);
  };

  let inputObj2 = {
    accessToken: store.getState().login.accessToken,
    data: {
      invoice_id: 1,
      status_type: "",
      status: 1,
    },
  };

  const updateMonthlyReports = async (data) => {
    try {
      let response;
      // Fetch records for the selected year
      response = await sendRequestUpdateMonthlyRevenueReportsAdmin({
        ...inputObj2,
        data: { invoice_id: 1, status_type: "", status: 1 },
      });
      setReportsList(response);
    } catch (error) {
      console.log("Error fetching monthly reports:", error);
      setReportsFilterLoading(false);
    }
  };

  const pendingApprovedHandler = (e, item) => {
    console.log("🚀 ~ pendingApprovedHandler ~ e:", e.target.value, item);
    // updateMonthlyReports()
  };

  const paidUnpaidHandler = () => {};

  const notesHandler = () => {};
  return (
    <>
      <ModalComponent
        id={"update_invoice_status"}
        saveHandler={updateMonthlyReports}
        modalHeading={"Update Invoice Status"}
      >
        <select
          className="form-select"
          aria-label="Default select example"
          // onChange={(e) => pendingApprovedHandler(e, nestedItem)}
        >
          <option
            // selected={nestedItem.stats.invoice_status == null ? true : false}
            value="pending"
          >
            Pending
          </option>
          <option
            // selected={nestedItem.stats.invoice_status == null ? false : true}
            value="approved"
          >
            Approved
          </option>
        </select>
      </ModalComponent>

      <ModalComponent
        id={"update_is_invoice_paid_status"}
        saveHandler={updateMonthlyReports}
        modalHeading={"Update Invoice Paid Status"}
      >
        <select
          className="form-select"
          aria-label="Default select example"
          // onChange={(e) => paidUnpaidHandler(e, nestedItem)}
        >
          <option
            // selected={nestedItem.stats.is_invoice_paid == null ? true : false}
            value="unpaid"
          >
            Unpaid
          </option>
          <option value="paid">Paid</option>
        </select>
      </ModalComponent>

      <ModalComponent
        id={"update_notes"}
        saveHandler={notesHandler}
        modalHeading={"Update Notes"}
      >
        <textarea
          type="textarea"
          component="textarea"
          id="notes"
          name="notes"
          className="form-control"
          // required
          rows="4"
          // value={props?.formData?.custom_css}
          // onChange={(e) => {
          //   props.setFormData((formData) => ({
          //     ...formData,
          //     custom_css: e.target.value,
          //   }));
          // }}
        />
      </ModalComponent>

      <div className="col-12 col-xl-12 position-relative">
        <div className="card">
          <div className="w-100 px-3 pt-3 d-flex gap-2 align-items-center">
            <div className="w-50 d-flex gap-2 align-items-center">
              <div className="w-50 d-flex gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Month:
                </label>
                <DatePickerField
                  value={selectedMonth}
                  setValue={setSelectedMonth}
                  mode={"month"}
                />
              </div>

              <div className="w-50 d-flex gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Year:
                </label>
                <DatePickerField
                  value={selectedYear}
                  setValue={setSelectedYear}
                  mode={"year"}
                />
              </div>
            </div>

            <div>
              {reportsFilterLoading ? (
                <button
                  className="btn btn-primary d-flex gap-2 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Apply
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={applyFilterHandler}
                >
                  Apply
                </button>
              )}

              {/* <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#month_report_admin"
              >
                Launch modal
              </button> */}
            </div>
          </div>
          <hr />
          <table id="reports" className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Month</th>

                <th scope="col">Year</th>
                <th scope="col">Reported Revenue</th>
                <th scope="col">Final Revenue</th>

                <th scope="col">Review</th>
                <th scope="col">Status</th>
                <th scope="col">Notes</th>
              </tr>
            </thead>
            <tbody>
              {reportsList?.length > 0 ? (
                reportsList?.map((report, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      <tr className="table-info">
                        <td
                          onClick={() => {
                            setDisplayIndex(ind);
                            setDisplayItems(
                              displayItems?.length > 0 ? [] : report.items
                            );
                          }}
                        >
                          {report.month}
                          <i
                            className={`${
                              displayItems?.length > 0 && displayIndex == ind
                                ? "fa-solid fa-circle-minus"
                                : "fa-solid fa-circle-plus"
                            } ps-3`}
                          ></i>
                        </td>
                        <td>{report.year}</td>

                        <td>
                          {"$" +
                            sumOfFieldForRow(
                              report,
                              "invoice_reported_revenue"
                            )}
                        </td>
                        <td>
                          {"$" +
                            sumOfFieldForRow(report, "invoice_final_revenue")}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>

                      {displayIndex === ind &&
                        displayItems.length > 0 &&
                        displayItems.map((nestedItem, nestedIndex) => {
                          return (
                            // <></>
                            <tr key={nestedIndex}>
                              <td> {nestedItem.publisher_name}</td>
                              <td> </td>

                              <td>
                                {`$${
                                  nestedItem.stats.invoice_reported_revenue?.toFixed(
                                    2
                                  ) ?? (0.0).toFixed(2)
                                }`}
                              </td>
                              <td>
                                {`$${
                                  nestedItem.stats.invoice_final_revenue?.toFixed(
                                    2
                                  ) ?? (0.0).toFixed(2)
                                }`}
                              </td>
                              <td>
                                {nestedItem.stats.invoice_status == null
                                  ? "Pending"
                                  : "Approved"}{" "}
                                <i
                                  className="fa-solid fa-pen"
                                  data-bs-toggle="modal"
                                  data-bs-target="#update_invoice_status"
                                  onClick={() =>
                                    setSelectedReportToUpdate(nestedItem)
                                  }
                                ></i>
                              </td>
                              <td>
                                {nestedItem.stats.is_invoice_paid == null
                                  ? "Unpaid"
                                  : "Paid"}{" "}
                                <i
                                  className="fa-solid fa-pen"
                                  data-bs-toggle="modal"
                                  data-bs-target="#update_is_invoice_paid_status"
                                  onClick={() =>
                                    setSelectedReportToUpdate(nestedItem)
                                  }
                                ></i>
                              </td>
                              <td>
                                {nestedItem.stats?.notes
                                  ? nestedItem.stats?.notes
                                  : "None"}{" "}
                                <i
                                  className="fa-solid fa-pen"
                                  data-bs-toggle="modal"
                                  data-bs-target="#update_notes"
                                  onClick={() =>
                                    setSelectedReportToUpdate(nestedItem)
                                  }
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="12">No records found …</td>
                </tr>
              )}
            </tbody>
          </table>
          <hr />
          {/* <PaginationComponent /> */}
        </div>
        {/* {apiData == null && <Spinner/>} */}
      </div>
    </>
  );
};
export default MonthlyRevenueReportsListAdmin;
