import React, { useEffect, useState } from "react";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { format } from "date-fns";

const DatePickerField = ({ value, setValue, mode }) => {
  const [date, setDate] = useState(value ?? "");

  const formatDate = (date) => format(date, "yyyy-MM-dd");
  const onChange = (jsDate, dateString) => {
    // let formatedDate = formatDate(jsDate)
    // if(formatedDate){
    setValue(jsDate);
    // }
  };
  useEffect(() => {
    setDate(value);
  }, [value]);
  return (
    <div className="w-100">
      <DatePickerInput
        startMode={mode}
        fixedMode={mode ? true : false}
        onChange={onChange}
        value={date}
        className=""
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        showOnInputClick={true}
      />
    </div>
  );
};

export default DatePickerField;
