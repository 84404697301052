import React from "react";
import AppStore from "../../config/AppStore";
import { useNavigate } from "react-router-dom";
import {MonthlyRevenueReportsList} from "../reportsView";

const MonthlyRevenueReports = () => {
    let store = AppStore.getAppStoreInstance();
    const navigate = useNavigate(); // we can pass navigation to clickhandler
    const inputObj = {
        accessToken: store.getState().login.accessToken,
        url: "create",
        history: navigate,
    };
  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Monthly Revenue Reports</h1>
          <p style={{color: 'red'}}>Report for prior month gets finalized by the 15th of every consecutive month.</p>
          <hr />
          <MonthlyRevenueReportsList />
        </div>
      </main>
    </div>
  );
};

export default MonthlyRevenueReports;