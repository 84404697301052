// EditModal.js

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import beautify from "js-beautify";
import Alert from "./common/Alert";
Modal.setAppElement("#root"); // Set the app root element

const EditModal = ({ isOpen, onClose, data }) => {
  console.log("🚀 ~ EditModal ~ data:", data)
  const [showCopiedMsg, setshowCopiedMsg] = useState(false);
  const [alertIndex, setAlerIndex] = useState(1)
  useEffect(()=>{
    if(showCopiedMsg){
      setTimeout(()=>{
        setshowCopiedMsg(false)
      },3000)
    }
  },[showCopiedMsg])
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Embed Script"
      className="custom-modal"
    >
      {/* Modal content */}
      <div>
        <h2>Embed Script</h2>
        
        <p>Copy script and place inside the head tag</p>


        {showCopiedMsg && alertIndex == 2 && 
        <div style={{display: 'flex', justifyContent: 'center', opacity: showCopiedMsg ? 1 : 0, transition: 'opacity 2s ease'}}> 
          <Alert message='Copied to clipboard!' alertType='success'/>
        </div>
        }
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className=" col-md-4">
                <h5 className="card-title mb-0">HEADER SCRIPT:</h5>
              </div>
              <div className=" col-md-8 text-align-right">
                <CopyToClipboard text={data.header_script} onCopy={()=> {setshowCopiedMsg(true); setAlerIndex(2)}}>
                  <button className="btn btn-primary btn-sm">
                    <i className="align-middle me-2 fas fa-fw fa-copy"></i> Copy
                    to Clipboard
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <p>{`Put the below script at the top of your page, within the head section: <head>`}</p>
            <textarea
              id="header_code"
              className="form-control"
              rows="2"
              columns="4"
              value={data.header_script}
              readOnly
            ></textarea>
          </div>
        </div>

        <p>Copy script and place inside the footer in script tag</p>
        {showCopiedMsg && alertIndex == 3 && 
        <div style={{display: 'flex', justifyContent: 'center', opacity: showCopiedMsg ? 1 : 0, transition: 'opacity 2s ease'}}> 
          <Alert message='Copied to clipboard!' alertType='success'/>
        </div>
        }
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className=" col-md-4">
                <h5 className="card-title mb-0">FOOTER SCRIPT:</h5>
              </div>
              <div className=" col-md-8 text-align-right">
                <CopyToClipboard
                  text={"<script>"+data.ad_settings+"</script>"}
                  onCopy={()=> {setshowCopiedMsg(true); setAlerIndex(3)}}
                >
                  <button className="btn btn-primary btn-sm">
                    <i className="align-middle me-2 fas fa-fw fa-copy"></i> Copy
                    to Clipboard
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <p>{`Put the below script at the bottom of the page, before close of body tag: </body>`}</p>

            <textarea
              id="footer_code"
              className="form-control"
              rows="8"
              columns="4"
              value={`<script>
${beautify(data.ad_settings, { indent_size: 2 })}
</script>`}
              readOnly
            ></textarea>
          </div>
        </div>

        <p>Place HTML element(div) on necesary location of page</p>

        {showCopiedMsg && alertIndex == 1 && 
        <div style={{display: 'flex', justifyContent: 'center', opacity: showCopiedMsg ? 1 : 0, transition: 'opacity 2s ease'}}> 
          <Alert message='Copied to clipboard!' alertType='success'/>
        </div>
        }

        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className=" col-md-4">
                <h5 className="card-title mb-0">Ad-Unit HTML:</h5>
              </div>
              <div className=" col-md-8 text-align-right">
                <CopyToClipboard text={data.html} onCopy={()=> {setshowCopiedMsg(true); setAlerIndex(1)}}>
                  <button className="btn btn-primary btn-sm">
                    <i className="align-middle me-2 fas fa-fw fa-copy"></i> Copy
                    to Clipboard
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <p>
              Put the below html code anywhere in your page, where you would
              like to render xOptin Ad system.
            </p>
            <textarea
              id="html_code"
              className="form-control"
              rows="2"
              value={data.html}
              readOnly
            ></textarea>
          </div>
        </div>

        <div className="text-align-right">
          <button className="btn btn-success btn-lg" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
