import { createSlice, createReducer, createAction } from "@reduxjs/toolkit";
import Constants from "../config/Constants";
import AppStore from "../config/AppStore";
import { sendIsUserLoggedInRequest } from "./App";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendPOSTRequest,
  validateUserToken,
  redirectToDashboardPageIfTokenIsValid1
} from "../utils/common_functions";


const initStateValues = Constants.AppStoreState;
initStateValues.error = null;

const loginSlice = createSlice({
  name: "logins",
  initialState: initStateValues,
  reducers: {
    loginAction(state, action) {
      console.log("loginReducer", action);
      executeLoginReducer(state, action);

      addToSessionStorage(
        ["userToken", "userId", 'isStaff', "accessToken"],
        [state.userToken, state.userId, state.isStaff, state.accessToken]
      );
      const inputObj = { userToken: state.userToken };
      console.log('access token returend:');
      console.log(state);
      // AppStore.sessionKeepAliveTimer = setInterval(
      //   () => validateUserToken(inputObj),
      //   Constants.APP_SESSION_KEEP_ALIVE_INTERVAL
      // );
      redirectToDashboardPageIfTokenIsValid1();
    },
    logoutAction(state, action) {
      executeLogoutReducer(state, action);
      clearInterval(AppStore.sessionKeepAliveTimer);
      AppStore.sessionKeepAliveTimer = null;
      clearSessionStorage();
    },
    setServerErrorAction(state, action) {
      executeSetServerErrorReducer(state, action);;;
    },
  },
});
const executeSetServerErrorReducer = (state, action) => {
  state.error = action.payload.error;
};
const executeLoginReducer = (state, action) => {
	state.currentExecutedAction = action.type;
	state.userId = action.payload.userId;
  state.userToken = action.payload.userToken;
  state.isStaff = action.payload.isStaff;
  state.error = null
}

const executeLogoutReducer = (state, action) => {
	state.currentExecutedAction = action.type;
	state.userToken = state.userId = state.isStaff = null;
}

const sendLoginRequest = (dataToPost) => {
  console.log('Sending Login Request!');
  console.log(dataToPost);
  const store = AppStore.getAppStoreInstance();
  const inputObj = {
    userId: 1,
    userToken: 3,
    isStaff: 1,
  };
  
  // return;
  const url = Constants.APP_SERVER_AUTHENTICATE_USER_URL;
  const headersToPost = { "Content-type": "application/json;charset=UTF-8" };
  sendPOSTRequest(
    url,
    dataToPost,
    headersToPost,
    loginResponseSuccessHandler,
    loginResponseErrorHandler
  );
  store.dispatch(loginAction(inputObj));
};
function loginResponseSuccessHandler(response) {

  
  if (response.access) {
    const store = AppStore.getAppStoreInstance();
    const inputObj = { userId: response.userId, userToken: response.access, isStaff: response.isStaff };
    console.log('loginResponseSuccessHandler');
    console.log(response);  
    store.dispatch(loginAction(inputObj));
    
  }
}
function loginResponseErrorHandler(error) {
  if (error.hasOwnProperty("non_field_errors")) {
    if (error.non_field_errors.length) {
      const store = AppStore.getAppStoreInstance();
      const inputObj = { error: error.non_field_errors[0] };
      store.dispatch(setServerErrorAction(inputObj));
    }
  }
}
export const readServerError = (state) => {
  return state.error;
};
export const loginBtnClickHandler = (inputObj) => {
  sendLoginRequest(inputObj);
};

// Extract the action creators object and the reducer
const { actions, reducer } = loginSlice;
// Extract and export each action creator by name
export const { loginAction, logoutAction, setServerErrorAction } = actions;
// Export the reducer, either as a default or named export
export default reducer;
