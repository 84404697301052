import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  sendRequestGetAdUnis,
  showEmbedScriptLinkHandler,
} from "../../view_logics/AddUnits";
import { editPublisherLinkClick } from "../../view_logics/Publishers/EditPublisher";
import { editAddUnitLinkClick } from "../../view_logics/AddUnits/EditAddUnit";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";
import AppStore from "../../config/AppStore";

import EditModal from "../../components/Modal";
import { capitalizeFirstWord } from "../../../utils/common_functions";

const AddUnitsList = (store) => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleEditClick = (data) => {
    // console.warn(data);
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = (one) => {
    setIsModalOpen(false);
  };

  store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editAdvertiser",
    history: navigate,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        inputObj.data = [];
        inputObj.dataHander = setData; // you setting data in this state you don't need to update setData again
        await sendRequestGetAdUnis(inputObj);
        let jsonData = inputObj.data;
        console.log("returned data is:");
        console.log(inputObj);
        // setData(jsonData);  //this is bad request is recursion
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const showType = (type) => {
    console.log("🚀 ~ showType ~ type:", type)
    let displayValue = '';
    if(type == 'custom_template'){
      displayValue = 'Custom Full Page'
    }
    if(type == 'custom_ad_sequence'){
      displayValue = 'Custom Ad Sequence'
    }
    if(displayValue != ''){
      return displayValue
    }else{
      return capitalizeFirstWord(type)
    }
  }

  return (
    <div className="col-12 col-xl-12">
      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Website</th>
              <th>Type</th>
              <th className="d-none d-md-table-cell">No of Offers</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((advertiser, index) => (
                <tr
                  key={advertiser.unique_id}
                  className={index % 2 === 0 ? "table-success" : ""}
                >
                  <td className="text-truncate" style={{ maxWidth: "130px" }}>{advertiser.unique_id}</td>
                  <td className="text-truncate" style={{ maxWidth: "130px" }}>{`${advertiser.website_name}_${advertiser.name}`}</td>
                  <td className="text-truncate" style={{ maxWidth: "100px" }}>
                    {advertiser.name}
                  </td>
                  <td>{showType(advertiser.type)}</td>
                  <td className="d-none d-md-table-cell">
                    {advertiser.no_of_offers}
                  </td>
                  <td className="table-action">
                    <span
                    className="cursor-pointer"
                      onClick={() => {
                        inputObj.id = advertiser.id;
                        editAddUnitLinkClick(inputObj);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-2 align-middle"
                      >
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </span>
                    &nbsp;
                    <span
                    className="cursor-pointer"
                      onClick={() => {
                        inputObj.id = advertiser.id;
                        inputObj.openModalHandler = handleEditClick;
                        showEmbedScriptLinkHandler(inputObj);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-code align-middle me-2"
                      >
                        <polyline points="16 18 22 12 16 6"></polyline>
                        <polyline points="8 6 2 12 8 18"></polyline>
                      </svg>
                    </span>
                    {/* <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash align-middle">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      </svg>
                    </a> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Loading data...</td>
              </tr>
            )}
          </tbody>
        </table>
        {isModalOpen ? (
          <EditModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            data={modalData}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default AddUnitsList;
