import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Advertisers,
  CreateAdvertiser,
  EditAdvertiser,
} from "./advertiserView";
import { Dashboard } from "./dashboardView";
import {
  AdvertiserOffers,
  CreateAdvertiserOffer,
  EditAdvertiserOffer,
} from "./advertiserOffersView";
import { Publishers, CreatePublisher } from "./publisherView";
import { Websites, CreateWebsite } from "./websiteView";
import { Tags, CreateTag } from "./tagView";
import { AddUnits, CreateAddUnit, EditAddUnit } from "./addUnitView";
import { isAccessTokenValid } from "../utils/common_functions";
import useAlertOnNavigation from "../utils/useAlertOnNavigation";
import Alert from "../components/common/Alert";
import CookiesUtility from "./cookiesUtility/CookiesUtility";
import {
  OfferStatReports,
  PublisherOfferStatReports,
  MonthlyRevenueReports,
  MonthlyRevenueReportsAdmin,
} from "./reportsView";

import { UpdateContactInfo } from "../views/contactInfo/index";
import { ResetPassword } from "../../pages/ResetPassword";
import CustomAdSequencePage from "../components/static/customAdSequencePage";

function ParentView() {
  const accessToken = useSelector(isAccessTokenValid);
  const { showAlert, alertType, message } = useAlertOnNavigation();
  const { username, userType, mainUser, publisherName } = useSelector(
    (state) => state?.login
  );

  const renderAdminRoutes = () => (
    <>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/advertiser" element={<Advertisers />} />
      <Route path="/advertiser/create" element={<CreateAdvertiser />} />
      <Route path="/advertiser/edit/:id" element={<EditAdvertiser />} />
      <Route path="/offers" element={<AdvertiserOffers />} />
      <Route path="/offers/create" element={<CreateAdvertiserOffer />} />
      <Route path="/offers/edit/:id" element={<EditAdvertiserOffer />} />
      <Route path="/publishers" element={<Publishers />} />
      <Route path="/publishers/create" element={<CreatePublisher />} />
      <Route path="/publishers/edit/:id" element={<CreatePublisher />} />
      <Route path="/websites" element={<Websites />} />
      <Route path="/websites/create" element={<CreateWebsite />} />
      <Route path="/websites/edit/:id" element={<CreateWebsite />} />
      <Route path="/tags" element={<Tags />} />
      <Route path="/tags/edit/:id" element={<CreateTag />} />
      <Route path="/tags/create" element={<CreateTag />} />
      <Route path="/adunits" element={<AddUnits />} />
      <Route path="/adunits/create" element={<CreateAddUnit />} />
      <Route path="/adunits/edit/:id" element={<EditAddUnit />} />
      <Route path="/cookiesutility" element={<CookiesUtility />} />
      <Route
        path="/reports/offer_stats_report"
        element={<OfferStatReports />}
      />
      <Route
        path="/reports/monthly_revenue_report"
        element={<MonthlyRevenueReportsAdmin />}
      />
      {/* <Route
        path="/reports/publisher_offer_stats"
        element={<PublisherOfferStatReports />}
      />
      <Route
        path="/reports/monthly_revenue_report"
        element={<MonthlyRevenueReports />}
      /> */}
      <Route path="/contact_info" element={<UpdateContactInfo />} />
      <Route path="/profile/reset-password" element={<ResetPassword />} />

      {/* static routes */}
    </>
  );

  const renderPublisherRoutes = () => (
    <>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/websites" element={<Websites />} />
      <Route path="/websites/create" element={<CreateWebsite />} />
      <Route path="/websites/edit/:id" element={<CreateWebsite />} />
      <Route path="/adunits" element={<AddUnits />} />
      <Route path="/adunits/create" element={<CreateAddUnit />} />
      <Route path="/adunits/edit/:id" element={<EditAddUnit />} />
      <Route
        path="/reports/publisher_offer_stats"
        element={<PublisherOfferStatReports />}
      />
      <Route
        path="/reports/monthly_revenue_report"
        element={<MonthlyRevenueReports />}
      />
      <Route path="/contact_info" element={<UpdateContactInfo />} />
      {/* Add routes accessible to publishers here */}
    </>
  );
  return (
    <>
      {showAlert && (
        <div
          className="position-absolute d-flex w-100 justify-content-center"
          style={{ top: alertType == "danger" ? "100px" : "140px" }}
        >
          <Alert
            message={message}
            alertType={alertType ? alertType : "primary"}
          />
        </div>
      )}
      <Routes>
        {accessToken !== null ? (
          userType === "admin" ? (
            renderAdminRoutes()
          ) : (
            renderPublisherRoutes()
          )
        ) : (
          <Route path="/*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    </>
  );
}
export default ParentView;
