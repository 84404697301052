import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "../views/loginView";
import ParentView from "./ParentView";
import { Sidebar, Navbar } from "../components";

import "../../styles/_app.scss";
import { ResetPassword } from "../../pages/ResetPassword";
import CustomAdSequencePage from "../components/static/customAdSequencePage";
import CustomAdFullPage from "../components/static/customAdFullPage";
// import CustomHeaderPage from "../components/static/customHeaderPage";
import CustomSidebarPage from "../components/static/customSidebarPage";
import CustomMainContentPage from "../components/static/customMainContentPage";
import CustomExitPopupPage from "../components/static/customExitPopupPage";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="*"
        element={
          <div className="wrapper">
            <Sidebar />
            <div className="main position-relative">
              <Navbar />
              <ParentView />
            </div>
          </div>
        }
      ></Route>

      <Route path="/custom_ad_sequence" element={<CustomAdSequencePage />} />
      <Route path="/custom_ad_full" element={<CustomAdFullPage />} />


      {/* <Route path="/custom_header" element={<CustomHeaderPage />} /> */}
      <Route path="/custom_sidebar" element={<CustomSidebarPage />} />
      <Route path="/custom_main_content" element={<CustomMainContentPage />} />
      <Route path="/custom_exit_popup" element={<CustomExitPopupPage />} />
    </Routes>
  );
}
export default App;
