import Constants from "../../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  validateUserToken,
  redirectToPage,
} from "../../utils/common_functions";

export const createAdvertiserOfferBtnClick = (dataToPost) => {
  console.log("createAdvertiserOfferBtnClick");
  console.log(dataToPost);
  // let store = AppStore.getAppStoreInstance();
  // const inputObj = {
  //   userToken: store.getState().login.accessToken,
  // };
  redirectToPage(dataToPost);
};

export const sendCreateAdvertiserOfferFormSubmitRequest = (inputObj) => {
  console.log("Sending Create ADvertiser Offer Request!");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_CREATE_ADVERTISER_OFFER_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  // dataToPost.stateChangeHandler = inputObj.alreadyExistsHandler;
  dataToPost.history = inputObj.history;
  dataToPost.recirectUrl = inputObj.url;
  dataToPost.stateChangeHandler = inputObj.stateChangeHandler;
  dataToPost.setActiveTab = inputObj.setActiveTab;
  dataToPost.loadingHandler = inputObj?.loadingHandler;
  sendPOSTRequest(
    url,
    dataToPost,
    headersToPost,
    sendCreateAdvertiserOfferFormSubmitResponseSuccessHandler,
    sendCreateAdvertiserOfferFormSubmitResponseErrorHandler
  );
  // inputObj.history(inputObj.url);
};

function sendCreateAdvertiserOfferFormSubmitResponseSuccessHandler(
  response,
  request
) {
  console.log(
    "🚀 ~ file: CreateAdvertiserOffer.js:51 ~ sendCreateAdvertiserOfferFormSubmitResponseSuccessHandler ~ response:",
    response,
    request
  );
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  console.log("sendCreateAdvertiserOfferFormSubmitResponseSuccessHandler");
  console.log(response);
  request?.loadingHandler && request?.loadingHandler(false);

  if (response.status == "error") {
    // request.stateChangeHandler(true);
    // request.history('/create');
  } else {
    console.log("advertiser offer created succesfully showing alert");
    // request.history(request.recirectUrl + '/' + response.id);
    request.history(request.recirectUrl + "/" + response.id, {
      state: {
        from: "Advertiser Offer",
        type: "create",
        message: response.message,
      },
    });
    request.stateChangeHandler(response.id);
    request.setActiveTab(2);
  }
  // request.dataHander(response);
}

export const sendCreateAdvertiserOfferFormSubmitResponseErrorHandler = (
  error,
  request
) => {
  console.log("sendCreateAdvertiserOfferFormSubmitResponseErrorHandler");
  console.log(error);
  request?.loadingHandler && request?.loadingHandler(false);

  request.history("", {
    state: { from: "Advertiser Offer", type: "error", message: error.message },
  });
  // request.stateChangeHandler(true);
  // request.history('/create');
};
