import AppStore from "../config/AppStore";
import Constants from "../config/Constants";
import { logoutAction } from "../view_logics/LoginSlice";
import moment from "moment";

export const redirectToLoginPageIfTokenIsInvalid = (inputObj) => {
  console.log('Function redirectToLoginPageIfTokenIsInvalid.......');
  if (!inputObj.hasOwnProperty('history') || !inputObj.hasOwnProperty('userToken')) {
    return;
  }
  if (inputObj.userToken === null) {
    inputObj.history.push(Constants.APP_URI_LOGIN);
  }
}
export const redirectToDashboardPageIfTokenIsValid = (inputObj) => {
  if (!inputObj.hasOwnProperty('accessToken')) {
    return;
  }
  if (inputObj.accessToken !== null) {
    inputObj.history(Constants.APP_URI_DASHBOARD);
  } else if (inputObj.accessToken == null) {
    inputObj.history(Constants.APP_URI_LOGIN);
  }
}

export const isAccessTokenValid = (state) => {
  console.log(state.login.accessToken)
  return state.login.accessToken;
}

export const isRefreshTokenValid = (state) => {
  return state.login.refreshToken;
}


export const isUserTokenValid = (state) => {
  return state.userToken;
}
export const isStaffUser = (state) => {
  return state.isStaff;
}

export const readUserToken = (state) => {
  console.log('returning accesstoken: ' + state);
  return state.login.accessToken;
};

export const validateUserToken = (inputObj) => {

  if (inputObj.userToken === null) {
    return;
  }
  const url = Constants.APP_SERVER_VALIDATE_USER_TOKEN_URL;
  const dataToPost = {};
  const headersToPost = { "Content-type": "application/json;charset=UTF-8", 'Authorization': 'Token ' + inputObj.userToken };

  sendGETRequest(url, dataToPost, headersToPost, validateUserTokenResponseSuccessHandler, validateUserTokenResponseErrorHandler);
}
function validateUserTokenResponseSuccessHandler(response) {
  if (!response.session) {
    const store = AppStore.getAppStoreInstance();
    store.dispatch(logoutAction());
  }
}
function validateUserTokenResponseErrorHandler(error) {
  console.log('Error', error);
}
export const addToSessionStorage = (keys, values) => {
  if (!Array.isArray(keys) || !Array.isArray(values) || keys.length !== values.length) {
    return;
  }
  keys.forEach((key, index) => sessionStorage.setItem(key, values[index]));
}
export const removeFromSessionStorage = (key) => {
  if (key === null) {
    return;
  }
  sessionStorage.removeItem(key);
}
export const getFromSessionStorage = (keys) => {
  if (!Array.isArray(keys) || keys.length === 0) {
    return null;
  }
  let valuesToReturn = [];
  keys.forEach((key, index) => valuesToReturn.push(sessionStorage.getItem(key)));
  return valuesToReturn;
}
export const clearSessionStorage = () => {
  sessionStorage.clear();
}

export const sendPOSTRequest = (url, dataToPost, headersToPost, successHandler, errorHandler) => {
  console.log("🚀 ~ sendPOSTRequest ~ dataToPost:", dataToPost)
  
  fetch(url, {
    method: "POST",
    // mode: 'cors',
    headers: headersToPost,
    //body:  JSON.stringify(dataToPost),
    body:
      dataToPost instanceof FormData
        ? dataToPost
        : JSON.stringify(dataToPost),
  })
    .then((res) => {
      console.log("🚀 ~ .then ~ res:", res)
      if(res.status == 401){
        if(dataToPost?.email && dataToPost?.password){
          return res.json()
        } else {
          const store = AppStore.getAppStoreInstance();
          return store.dispatch(logoutAction())
        }
        
      }
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then(
      (result) => {
        // console.log('SUCCESS!!!!');
        successHandler(result, dataToPost);
      },
      (error) => {
        console.log("Error", error);
        //errorHandler(JSON.stringify(error));
        if (typeof error.json === "function") {
          error.json().then((errorMessage) => {
            errorHandler(errorMessage, dataToPost);
          });
        } else {
          console.log(error);
        }
      }
    );
}

export const sendGETRequestASync = async (url, dataToPost, headersToPost, successHandler, errorHandler) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: headersToPost,
    });
    if(response.status == 401){
      const store = AppStore.getAppStoreInstance();
      return store.dispatch(logoutAction())
    }

    if (!response.ok) {
      throw response;
    }

    const result = await response.json();
    successHandler && successHandler(result, dataToPost);
    return result;
  } catch (error) {
    console.log('Error', error);
    if (typeof error.json === 'function') {
      const errorMessage = await error.json();
      errorHandler(errorMessage);
    } else {
      console.log(error);
    }
    throw new Error('Failed to send GET request');
  }
};

export const sendPUTRequest = (
  url,
  dataToPost,
  headersToPost,
  successHandler,
  errorHandler
) => {
  fetch(url, {
    method: "put",
    headers: headersToPost,
    body: JSON.stringify(dataToPost),
  })
    .then((res) => {
      if(res.status == 401){
        const store = AppStore.getAppStoreInstance();
        return store.dispatch(logoutAction())
      }
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then(
      (result) => {
        successHandler(result, dataToPost);
      },
      (error) => {
        console.log("Error", error);
        //errorHandler(JSON.stringify(error));
        if (typeof error.json === "function") {
          error.json().then((errorMessage) => {
            errorHandler(errorMessage, dataToPost);
          });
        } else {
          console.log(error);
        }
      }
    );
};

export const sendGETRequest = (
  url,
  dataToPost,
  headersToPost,
  successHandler,
  errorHandler
) => {
  var responseType = "json";
  if (dataToPost.responseType !== undefined && dataToPost.responseType === 'text') {
    responseType = "text";
  }
  const queryString = Object.keys(dataToPost)
    .map(
      (key) =>
        typeof dataToPost[key] !== "function" && `${key}=${dataToPost[key]}`
    )
    .join("&");
  // Object.keys(dataToPost).forEach((key) => console.log(dataToPost[key]));
  //   Object.keys(dataToPost).forEach(key => url.searchParams.append(key, dataToPost[key]))



  fetch(url.concat("?", queryString), {
    method: "get",
    headers: headersToPost,
  })
    .then((res) => {
      if(res.status == 401){
        const store = AppStore.getAppStoreInstance();
        return store.dispatch(logoutAction())
      }
      return responseType === 'text' ? res.text() : res.json()
    })
    .then(
      (result) => {
        successHandler(result, dataToPost);
      },
      (error) => {
        console.log("Error", error);
        errorHandler(error);
      }
    )
    .catch(error => {
      console.log("🚀 ~ file: common_functions.js:228 ~ error:", error)
    })
};

export const sendDeleteRequest = (
  url,
  dataToPost,
  headersToPost,
  successHandler,
  errorHandler
) => {
  fetch(url, {
    method: "delete",
    headers: headersToPost,
    body: JSON.stringify(dataToPost),
  })
    .then((res) => {
      if(res.status == 401){
        const store = AppStore.getAppStoreInstance();
        return store.dispatch(logoutAction())
      }
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then(
      (result) => {
        successHandler(dataToPost, result);
      },
      (error) => {
        console.log("Error", error);
        //errorHandler(JSON.stringify(error));
        if (typeof error.json === "function") {
          error.json().then((errorMessage) => {
            errorHandler(errorMessage);
          });
        } else {
          console.log(error);
        }
      }
    );
};
export const makeStartEndDatesFromStr = (str, format = "MM/DD/YYYY") => {
  //	var format = 'MM/DD/YYYY';
  var startDate = moment().format(format),
    endDate = moment().format(format);
  if (str == "") {
    startDate = "";
    endDate = "";
  }
  if (str == "today") {
    startDate = moment().format(format);
    endDate = moment().format(format);
  }
  if (str == "yesterday") {
    startDate = moment().subtract(1, "days").format(format);
    endDate = moment().subtract(1, "days").format(format);
  }
  if (str == "this_week") {
    startDate = moment().days("Monday").format(format);
    endDate = moment().format(format);
  }
  if (str == "last_week") {
    //
    startDate = moment()
      .subtract(1, "week")
      .startOf("week")
      .add(1, "day")
      .format(format);
    endDate = moment()
      .subtract(1, "week")
      .endOf("week")
      .add(1, "day")
      .format(format);
  }
  if (str == "this_month") {
    startDate = moment().startOf("month").startOf("month").format(format);
    endDate = moment().format(format);
  }
  if (str == "last_month") {
    //
    startDate = moment().subtract(1, "month").startOf("month").format(format);
    endDate = moment().subtract(1, "month").endOf("month").format(format);
  }
  if (str == "this_year") {
    startDate = moment().startOf("year").format(format);
    endDate = moment().format(format);
  }
  if (str == "last_thirty_days") {
    startDate = moment().subtract(30, "days").format(format);
  }
  if (str == "past_year") {
    startDate = moment().subtract(365, "days").format(format);
  }
  return { startDate: startDate, endDate: endDate };
};
export const getToolbarHeadingForCurrentPage = (pathname) => {
  var heading = "Dashboard";
  var currentPathName = pathname.substring(1);
  if (currentPathName == "") {
    return heading;
  } else if (currentPathName == "daily_profit_report") {
    heading = "Daily Profit Report";
  } else if (
    currentPathName == "newsletter" ||
    currentPathName == "create_newsletter"
  ) {
    heading = "NewsLetters";
  } else if (currentPathName == "ticker_data") {
    heading = "Ticker Data";
  } else if (currentPathName == "option_chart") {
    heading = "Option & Ticker Charts";
  } else if (currentPathName == "ticker_price_action_chart") {
    heading = "Price Action Chart";
  } else if (currentPathName == "ticker_support_resistance_chart") {
    heading = "Support & Resistance";
  } else if (currentPathName == "strategy_ticker") {
    heading = "Strategy Tickers";
  } else if (currentPathName == "strategy_performance") {
    heading = "Strategy Performances";
  }
  return heading;
};

export const isMobileView = () => {
  return window.innerWidth < 500;
}


export const redirectToPageIfTokenIsValid = (inputObj) => {
  if (!inputObj.hasOwnProperty('accessToken')) {
    return;
  }
  if (inputObj.accessToken !== null) {
    inputObj.history(Constants.APP_URI_DASHBOARD);
  } else if (inputObj.accessToken == null) {
    inputObj.history(Constants.APP_URI_LOGIN);
  }
}

export const redirectToPage = (inputObj) => {
  if (!inputObj.hasOwnProperty('url') || !inputObj.hasOwnProperty('history')) {
    console.log('url or histgory obje not foudn while redirecitng to page');
    return;
  }
  if (!inputObj.hasOwnProperty('accessToken')) {
    return;
  }
  if (inputObj.accessToken !== null) {
    inputObj.history(Constants.APP_URI_DASHBOARD);
  } else if (inputObj.accessToken == null) {
    inputObj.history(Constants.APP_URI_LOGIN);
  }
  inputObj.history(inputObj.url);
}